import React, { useEffect, useState } from "react";
import "./LatestProjects.css";
import projectImage1 from "../../Image/Rectangle 34.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { Url } from "../../function/FunctionApi";
import Skeleton from "react-loading-skeleton";
import moment from "moment-hijri";

import "react-loading-skeleton/dist/skeleton.css";

const LatestProjects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          `${Url}OrderForSubscribe/get-last4Order`
        );
        setProjects(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  console.log(projects);

  if (loading) {
    return (
      <div className="latest-projects-container" dir="rtl">
        <div className="container">
          <h2 className="section-title">آخر المشاريع</h2>
          <div className="cards-container">
            {[1, 2, 3, 4].map((index) => (
              <div key={index} className="project-card">
                <Skeleton height={200} width={250} />
                <div className="project-info">
                  <h3 className="project-title">
                    <Skeleton width={150} />
                  </h3>
                  <p className="order-number">
                    <Skeleton width={100} />
                  </p>
                  <p className="project-date">
                    <Skeleton width={80} />
                  </p>
                  <Skeleton height={30} width={120} />
                </div>
              </div>
            ))}
          </div>
          <Link to="/projects" className="view-all-button">
            عرض الكل
          </Link>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="latest-projects-container" dir="rtl">
      <div className="container">
        <h2 className="section-title">آخر المشاريع</h2>
        {projects.length === 0 ? (
          <p>لا توجد مشاريع حالياً لعرضها</p>
        ) : (
          <div className="cards-container">
            {projects.map((project) => (
              <div key={project.id} className="project-card">
                <img
                  src={
                    project.modelPhotos && project.modelPhotos.length > 0
                      ? project.modelPhotos[0].url
                          .toLowerCase()
                          .endsWith(".pdf")
                        ? projectImage1
                        : project.modelPhotos[0].url
                      : projectImage1
                  }
                  alt={project.faultType || "Project Image"}
                  className="project-image"
                />
                <div className="project-info">
                  <h3 className="project-title">
                    {project.faultType ||
                      (project.projectType === 1 ? "تنفيذ شبكة" : "عداد")}
                  </h3>
                  <p className="order-number">
                    رقم امر العمل: {project.faultNumber || project.orderNumber}
                  </p>
                  <p className="order-number">
                      الفرع: {project.branchName || project.orderNumber}
                  </p>
                      <p className="order-number">
                      الحاله: {project.isArchived  ==true ? ' غير مكتمل' : "مكتمل"}
                  </p>
                  <p className="project-date">
                    {`${moment(project.orderDate).format(
                      "iDD/iMM/iYYYY هـ"
                    )} | ${new Date(project.orderDate).toLocaleDateString()}`}
                  </p>

                  <Link
                    to={`/Request-projects/${project.id}`}
                    className="bg-gray-200 p-2 mr-2 rounded-sm text-[12px]"
                  >
                    الاطلاع علي الطلب
                  </Link>
                </div>
              </div>
            ))}
          </div>
        )}
        <Link to="/projects" className="view-all-button">
          عرض الكل
        </Link>
      </div>
    </div>
  );
};

export default LatestProjects;
