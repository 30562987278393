import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./Subscribers.css";
import img1 from "../../Image/Frame.png";
import img2 from "../../Image/Frame (1).png";
import img3 from "../../Image/Group (1).png";
import officeImage from "../../Image/engineer-workers-icon 1.png";
import { useNavigate } from "react-router-dom";
import { Riyadh, Hail } from "../../util/officeConstants.js";

function Subscribers() {
  const navigate = useNavigate();
  const { name } = useParams();
  let cards = [];
  const [isoffices, setIsOffices] = useState(false);
  const toggleIsOffices = () => setIsOffices(!isoffices);
  let title = "";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  switch (name) {
    case "maintains":
      cards = [
        {
          id: 1,
          image: img1,
          text: "اضافة طلب",
          link: "/maintain-projects",
          office: true,
        },
        {
          id: 2,
          image: img2,
          text: "الطلبات تحت التنفيذ",
          link: "/requests/Archived/maintains",
        },
        {
          id: 3,
          image: img3,
          text: " (تم التنفيذ)  الطلبات المكتملة",
          link: "/requests/Completed/maintains",
        },
      ];
      title = "الصيانه";
      break;
    case "Operations-Maintenance":
      cards = [
        {
          id: 1,
          image: img1,
          text: "اضافة طلب",
          link: "/operations-maintenance",
          office: true,
        },
        {
          id: 2,
          image: img2,
          text: "الطلبات تحت التنفيذ",
          link: "/requests/Archived/Operations-Maintenance",
        },
        {
          id: 3,
          image: img3,
          text: " (تم التنفيذ)  الطلبات المكتملة",
          link: "/requests/Completed/Operations-Maintenance",
        },
      ];
      title = "العمليات ";
      break;
    case "Projects":
      cards = [
        {
          id: 1,
          image: img1,
          text: "اضافة طلب",
          link: "/Request-projects",
          office: true,
        },
        {
          id: 2,
          image: img2,
          text: "الطلبات تحت التنفيذ",
          link: "/requests/Archived/Projects",
        },
        {
          id: 3,
          image: img3,
          text: " (تم التنفيذ)  الطلبات المكتملة",
          link: "/requests/Completed/Projects",
        },
      ];
      title = "المشاريع";
      break;
    default:
      cards = [
        {
          id: 1,
          image: img1,
          text: "اضافة طلب",
          link: "/special-projects",
          office: true,
        },
        {
          id: 2,
          image: img2,
          text: "الطلبات تحت التنفيذ",
          link: "/requests/Archived/special-projects",
        },
        {
          id: 3,
          image: img3,
          text: " (تم التنفيذ)  الطلبات المكتملة",
          link: "/requests/Completed/special-projects",
        },
      ];
      title = "المشاريع الخاصه";
  }
  const offices = localStorage.getItem("IsRiyadh") === "true" ? Riyadh : Hail;

  return (
    <div className="subscribers-container" dir="rtl">
      <div style={{ marginBottom: "70px" }}>
        <h2 className="title">{title} </h2>
        <p className="description">لبدء مشروعك، نحتاج إلى تخصيص تفضيلاتك.</p>
      </div>

      <div className="cards-container">
        {cards.map((card) => {
          if (
            name !== "special-projects" &&
            card.office !== undefined &&
            card.office === true
          ) {
            return (
              <div
                key={card.id}
                className="card-link DIV-relative"
                onClick={toggleIsOffices}
              >
                <div className="card">
                  <img
                    src={card.image}
                    alt={card.text}
                    className="card-image"
                  />
                  <p className="card-text">{card.text}</p>
                </div>
            { isoffices &&     <div className="office">
                  {isoffices &&
                    offices.map((office) => (
                      <Link
                        to={`${card.link}?office=${office.value}`}
                        key={office.id}
                        className="office-card"
                      >
                        <p className="office-text">{office.name}</p>
                      </Link>
                    ))}
                </div>}
              </div>
            );
          }
          return (
            <Link to={card.link} key={card.id} className="card-link">
              <div className="card">
                <img src={card.image} alt={card.text} className="card-image" />
                <p className="card-text">{card.text}</p>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default Subscribers;
