import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Url } from "../../function/FunctionApi";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import UploadIcon from "../../Image/UploadIcon.png";
import Swal from "sweetalert2";
import { useSearchParams } from "react-router-dom";

const createUrl = `${Url}OperationsAndMaintenaceRequest/create-operation`;
const updateUrl = `${Url}OperationsAndMaintenaceRequest/update`;

const Form = ({ userData, apiData }) => {
  const [searchParams] = useSearchParams();

  const office = searchParams.get("office");
  console.log("offfice+++++++++++++");
  console.log(office);
  const [formData, setFormData] = useState({
    ProjectType: "",
    FaultNumber: "",
    FaultType: "",
    OrderType: "",
    Office: office || "",
    ReceiveDateTime: "",
    Situation: "",
    District: "",
    Resources: "",
    Contractor: "",
    Consultant: userData.displayName,
    Note: "",
    SafetyViolationsExist: "",
    EstimatedValue: "",
    ActualValue: "",
    ExtractNumber: "",
  });

  const [fileData, setFileData] = useState({
    ModelPhotos: [],
    SitePhotos: [],
    SafetyWastePhotos: [],
  });

  const fileInputRefs = {
    ModelPhotos: useRef(null),
    SitePhotos: useRef(null),
    SafetyWastePhotos: useRef(null),
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingArchive, setLoadingArchive] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [error, setError] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");

  const token = userData?.token;

  const projectTypeMapping = {
    طوارئ: 1,
    احلال: 2,
    التعزيز: 3,
    "الجهد المتوسط": 4,
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "ProjectType") {
      setFormData((prev) => ({
        ...prev,
        ProjectType: projectTypeMapping[value],
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  useEffect(() => {
    if (apiData) {
      setFormData({
        ProjectType: apiData.projectType,
        FaultNumber: apiData.faultNumber || "",
        FaultType: apiData.faultType || "",
        Contractor: apiData.contractor || "",
        District: apiData.district || "",
        Resources: apiData.resources || "",
        Station: apiData.station || "",
        Consultant: apiData.consultant || "",
        OrderType: apiData.orderType,
        Situation: apiData.situation || "",
        ReceiveDateTime: apiData.receiveDateTime || "",
        Note: apiData.note || "",
        SafetyViolationsExist: apiData.safetyViolationsExist
          ? "exists"
          : "not-exists",
        EstimatedValue: apiData.estimatedValue,
        ActualValue: apiData.actualValue,
        ExtractNumber: apiData.extractNumber,
      });
      setFileData({
        ModelPhotos: apiData.modelPhotos || [],
        SitePhotos: apiData.sitePhotos || [],
        SafetyWastePhotos: apiData.safetyWastePhotos || [],
      });
    }
  }, [apiData]);

  const handleFileChange = (event, fileType) => {
    const uploadedFiles = Array.from(event.target.files);
    const newFiles = uploadedFiles.filter((file) => {
      if (file.type === "application/pdf") {
        return true;
      }
      return file.size <= 1 * 1024 * 1024 * 1024;
    });
    const rejectedFiles = uploadedFiles.filter((file) => {
      return (
        file.type !== "application/pdf" && file.size > 1 * 1024 * 1024 * 1024
      );
    });

    if (rejectedFiles.length) {
      setErrorMessage("بعض الملفات كانت كبيرة جدًا ولا يمكن رفعها.");
    } else {
      setErrorMessage("");
    }

    setFileData((prev) => ({
      ...prev,
      [fileType]: [...prev[fileType], ...newFiles],
    }));
  };

  const handleFileDelete = (fileType, fileName) => {
    setFileData((prev) => ({
      ...prev,
      [fileType]: prev[fileType].filter((file) => file.name !== fileName),
    }));
  };

  const openFileSelector = (fileType) => {
    fileInputRefs[fileType].current.click();
  };

  const handleApiFileDelete = async (fileType, fileId) => {
    const endpointMap = {
      ModelPhotos: "OperationsAndMaintenaceRequest/model-photo",
      SitePhotos: "OperationsAndMaintenaceRequest/site-photo",
      SafetyWastePhotos: "OperationsAndMaintenaceRequest/safety-photo",
    };

    const endpoint = `${Url}${endpointMap[fileType]}?photoId=${fileId}`;

    try {
      const response = await axios.delete(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setFileData((prev) => ({
          ...prev,
          [fileType]: prev[fileType].filter((item) => item.id !== fileId),
        }));

        Swal.fire({
          position: "center",
          icon: "success",
          title: "تم حذف الصوره بنجاح.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      console.error("Error deleting file", error);
      Swal.fire({
        position: "center",
        icon: "error",
        title: "فشل حذف الصورة",
        text: "يرجى المحاولة مرة أخرى.",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const handleSubmit = async (isArchive) => {
    setLoadingSave(true);
    setUploadProgress(0);

    const submissionData = new FormData();
    const changedData = {};

    Object.entries(formData).forEach(([key, value]) => {
      if (key === "SafetyViolationsExist") {
        const mappedValue =
          value === "exists" ? true : value === "not-exists" ? false : value;

        if (!apiData || mappedValue !== apiData[key]) {
          changedData[key] = mappedValue;
          submissionData.append(key, mappedValue);
        }
      } else {
        if (!apiData || value !== apiData[key]) {
          changedData[key] = value;
          submissionData.append(key, value);
        }
      }
    });

    submissionData.append("isArchive", isArchive);

    Object.entries(fileData).forEach(([key, files]) => {
      files.forEach((file) => {
        submissionData.append(key, file);
      });
    });

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setUploadProgress(percent);
        },
      };
      if (apiData) {
        submissionData.append("OrderNumber", apiData.faultNumber);
        await axios.put(updateUrl, submissionData, config);
      } else {
        await axios.post(createUrl, submissionData, config);
      }
      if (apiData) {
        setSuccessMessage("تم تحديث الطلب بنجاح.");
      } else {
        setSuccessMessage("تم إرسال الطلب بنجاح.");
      }
      setShowSuccessModal(true);
    } catch (error) {
      let errorMessage =
        error?.response?.data?.data ||
        "فشل إرسال النموذج. يرجى المحاولة مرة أخرى.";
      setErrorMessage(errorMessage);
      setShowErrorModal(true);
    } finally {
      setLoadingSave(false);
    }
  };

  const renderUploadSection = (
    label,
    fileType,
    buttonLabel,
    multiple = true
  ) => (
    <div className="upload-section">
      <h4>{label}</h4>
      <div className="upload-box">
        <span className="upload-icon">
          <img src={UploadIcon} alt="Upload" />
        </span>
        <p>{buttonLabel}</p>
        <input
          type="file"
          onChange={(e) => handleFileChange(e, fileType)}
          multiple={multiple}
          ref={fileInputRefs[fileType]}
          style={{ display: "none" }}
          accept=".jpg,.jpeg,.png,.pdf"
        />
        <div className="button-group">
          <button
            type="button"
            className="upload-button"
            onClick={() => openFileSelector(fileType)}
          >
            رفع ملف
          </button>
        </div>
      </div>
      <div className="file-list">
        {!apiData &&
          fileData[fileType].map((file, index) => (
            <div key={index} className="file-item">
              {file instanceof File ? (
                file.type === "application/pdf" ? (
                  <a
                    href={URL.createObjectURL(file)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="file-link"
                  >
                    {file.name}
                  </a>
                ) : (
                  <img
                    src={URL.createObjectURL(file)}
                    alt="Uploaded file"
                    className="uploaded-image"
                  />
                )
              ) : null}
              <button
                className="delete-button"
                onClick={() => handleFileDelete(fileType, file.name)}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </div>
          ))}
        {apiData &&
          fileData[fileType]?.map((item, index) => (
            <div key={index} className="file-item">
              {item.url &&
                (item.url.endsWith(".pdf") ? (
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="file-link"
                  >
                    {item.url.split("/").pop() || "ملف"}
                  </a>
                ) : (
                  <img
                    src={item.url}
                    className="uploaded-image"
                    alt="uploaded-image"
                  />
                ))}
              {item instanceof File ? (
                item.type === "application/pdf" ? (
                  <a
                    href={URL.createObjectURL(item)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="file-link"
                  >
                    {item.name}
                  </a>
                ) : (
                  <img
                    src={URL.createObjectURL(item)}
                    alt="Uploaded file"
                    className="uploaded-image"
                  />
                )
              ) : null}
              <button
                className="delete-button"
                onClick={() => handleApiFileDelete(fileType, item.id)}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </div>
          ))}
      </div>
    </div>
  );

  return (
    <div className="form-container" dir="rtl">
      <div className="radio-container">
        {/* <div className="container">
          <h2>قم بتحديد نوع المشروع</h2>
          <div className="radio-group">
            {Object.keys(projectTypeMapping).map((type) => (
              <label key={type}>
                <input
                  type="radio"
                  name="ProjectType"
                  value={type}
                  checked={formData.ProjectType === projectTypeMapping[type]}
                  onChange={handleChange}
                />
                <span className="radio-circle" />
                <span>{type}</span>
              </label>
            ))}
          </div>
        </div> */}
      </div>

      <div className="FormData">
        <div className="container">
          <h3>بيانات الطلب</h3>
          <p>Get a Quote Immediately Upon Form Submission</p>
          <div className="input-group">
            <div className="groub_fe">
              <label>رقم امر العمل</label>

              <input
                type="text"
                name="FaultNumber"
                placeholder="رقم امر العمل"
                value={formData.FaultNumber}
                onChange={handleChange}
                required
                readOnly={!!apiData}
              />
            </div>
            <div className="groub_fe">
              <label> رقم العطل</label>
              <input
                type="text"
                name="FaultType"
                placeholder="نوع العطل"
                value={formData.FaultType}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="input-group">
            <div className="groub_fe">
              <label> الحي</label>
              <input
                type="text"
                name="District"
                placeholder="الحي"
                value={formData.District}
                onChange={handleChange}
                required
              />
            </div>
            <div className="groub_fe">
              <label>وصف العمل</label>
              <input
                type="text"
                name="Resources"
                placeholder="المواد"
                value={formData.Resources}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="input-group">
            <div className="groub_fe">
              <label> المقاول</label>
              <input
                type="text"
                name="Contractor"
                placeholder="المقاول"
                value={formData.Contractor}
                onChange={handleChange}
                required
              />
            </div>
            <div className="groub_fe">
              <label>الاستشاري</label>
              <input
                type="text"
                name="Consultant"
                placeholder="الاستشاري"
                value={formData.Consultant}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="input-group">
            <div className="groub_fe">
              <label>نوع الطلب </label>
              <input
                key={"OrderType"}
                type="text"
                name={"OrderType"}
                placeholder={"نوع الطلب"}
                value={formData["OrderType"]}
                onChange={handleChange}
                required
              />
            </div>
            {/* <div className="groub_fe">
              <label> مده التنفيذ</label>
              <input
                datepicker
                key={"executeTime"}
                name={"executeTime"}
                value={formData["executeTime"]}
                type="text"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="مده التنفيذ"
              />
            </div> */}
          </div>
          <div className="input-group">
            <div className="groub_fe">
              <label> تاريح استلام امر الطلب</label>
              <input
                key={"ReceiveDateTime"}
                type="date"
                name={"ReceiveDateTime"}
                placeholder={"تاريخ استلام امر الطلب"}
                value={formData["ReceiveDateTime"]}
                onChange={handleChange}
                required
              />
            </div>
            <div className="groub_fe">
              <label>موقف التنفيذ</label>
              <select
                id="Situation"
                name="Situation"
                className="selectSituation"
                onChange={handleChange}
                value={formData.Situation}
              >
                <option value="pending">جاري</option>
                <option checked value="finish">
                  {" "}
                  منتهي
                </option>

                <option value="notFinished">لم يتم التنفيذ</option>
              </select>
            </div>
          </div>
          <div className="input-group">
            <div className="groub_fe">
              <label>القيمه المقدرة</label>
              <input
                type="text"
                name="EstimatedValue"
                placeholder="القيمة المقدرة"
                value={formData.EstimatedValue}
                onChange={handleChange}
                required
              />
            </div>
            <div className="groub_fe">
              <label>القيمه الفعلية</label>
              <input
                type="text"
                name="ActualValue"
                placeholder="القيمة الفعلية"
                value={formData.ActualValue}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="input-group">
            <div className="groub_fe">
              <label>رقم المستخلص</label>
              <input
                type="text"
                name="ExtractNumber"
                placeholder="رقم المستخلص"
                value={formData.ExtractNumber}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          {renderUploadSection(
            "المستندات",
            "ModelPhotos",
            "تصوير مستندات مع الختم"
          )}
          {renderUploadSection("صور الموقع", "SitePhotos", "صور الموقع")}

          <div className="violations">
            <h4>مخالفات السلامة</h4>
            <div className="radio-group">
              {["exists", "not-exists"].map((value) => (
                <label key={value}>
                  <input
                    type="radio"
                    name="SafetyViolationsExist"
                    value={value}
                    checked={formData.SafetyViolationsExist === value}
                    onChange={handleChange}
                  />
                  <span className="radio-circle" />
                  {value === "exists" ? "يوجد" : "لا يوجد"}
                </label>
              ))}
            </div>
          </div>

          {formData.SafetyViolationsExist === "exists" &&
            renderUploadSection(
              "صور مخالفات السلامة",
              "SafetyWastePhotos",
              "صور المخالفات"
            )}

          <textarea
            name="Note"
            placeholder="ملاحظات"
            value={formData.Note}
            onChange={handleChange}
            rows="4"
          ></textarea>

          {error && <div className="error-message">{error}</div>}
          <div className="button-group">
            <button
              className={`submit-button px-4 py-2 rounded ${
                loadingSave || formData.Situation !== "finish"
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-green-600"
              } text-white`}
              onClick={() => handleSubmit(false)}
              disabled={loadingSave || formData.Situation !== "finish"}
            >
              {loadingSave ? "جاري التحميل..." : "تم التنفيذ"}
            </button>

            <button
              type="button"
              className={`submit-button px-4 py-2 rounded ${
                loadingSave || formData.Situation === "finish"
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-green-600"
              } text-white`}
              style={{ background: "rgba(244, 67, 54, 1)", color: "white" }}
              onClick={() => handleSubmit(true)}
              disabled={loadingSave || formData.Situation === "finish"}
            >
              {loadingSave ? "جاري التحميل..." : "تحت التنفيذ"}
            </button>
          </div>
        </div>
      </div>

      <Modal show={loadingSave} onHide={() => setLoadingSave(false)}>
        <Modal.Header>
          <Modal.Title>جارٍ رفع البيانات</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>جاري رفع البيانات، الرجاء الانتظار...</p>
          <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
        </Modal.Body>
      </Modal>
      {/* Success Modal */}
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>نجاح</Modal.Title>
        </Modal.Header>
        <Modal.Body>{successMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowSuccessModal(false)}
          >
            إغلاق
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Error Modal */}
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>خطأ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{errorMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
            إغلاق
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Form;
