import { useState, useEffect } from "react";
import { m, motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl } from '@fortawesome/free-solid-svg-icons';
import Item from "./item";
import { useNavigate } from 'react-router-dom';
import { getCookie } from "../../Pages/Login/Login";
import LogOut from "../../Component/LogOut/LogOut";
import imgUser from "../../Image/team-01.png";
import "./Sidebar.css";

function Sidebar() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setOpen(false); 
        }
    }, []);

    const handleLogoutClick = () => {
        setLogoutDialogOpen(true);
    };

    const functionLogout = () => {
        document.cookie = `${encodeURIComponent("user")}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
        navigate('/');
    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen); 
    };

    const cookieValue = JSON.parse(getCookie("user")) || {};

    const sideContainerVariants = {
        true: { width: "13rem" },
        false: { width: "5rem", transition: { delay: 0.6 } },
    };

    const sidebarVariants = {
        true: { width: "200px" },
        false: { width: "5rem", transition: { delay: 0.4 } },
    };

    const userCardStyles = {
        padding: open ? "15px" : "10px 0px",
        width: open ? "100%" : "100%",
    };

    const userImageStyles = {
        width: open ? '50px' : '25px',
        height: open ? '50px' : '25px',
        marginRight: open ? '10px' : '0',
    };

    const userNameStyles = {
        display: open ? 'block' : 'none',
    };

    const userbutton = {
        fontSize: open ? '' : '8px',
        padding: open ? "10px 20px" : "0px",
        margin: open ? "10px 0px" : "10px 5px"
    };

    return (
        <>
            <motion.div
                data-open={open}
                variants={sideContainerVariants}
                initial={`${open}`}
                animate={`${open}`}
                className="sidebar_container"
            >
                <motion.div
                    className="sidebar"
                    initial={`${open}`}
                    animate={`${open}`}
                    variants={sidebarVariants}
                >
                    <motion.div
                        whileHover={{
                            scale: 1.2,
                            rotate: 180,
                            backgroundColor: "rgba(255, 255, 255, 0.3)",
                            backdropFilter: "blur(3.5px)",
                            border: "1px solid rgba(255, 255, 255, 0.18)",
                            transition: { delay: 0.2, duration: 0.4 },
                        }}
                        onClick={handleToggle}
                        className="lines_icon"
                    > 
                    
                        <FontAwesomeIcon icon={faListUl} />
                    </motion.div>

                    <div className="group">
                        <Item id="one" icon="fa-duotone fa-solid fa-chart-pie-simple" name="الرئيسيه" link="/home-page" />
                        <Item id="two" icon="fa-light fa-chart-simple" name="بحث الطلبات" link="/search-requests" />
                        <Item id="three" icon="fa-light fa-cart-shopping" name="الطلبات" link="/orders" />
                        <Item id="four" icon="fa-light fa-bag-shopping" name="اضافة حساب" link="/accounts" />
                        <Item id="five" icon="fa-light fa-user-helmet-safety" name="المهندسين" link="/engineers" />
                    </div>

                    {cookieValue && (
                        <motion.div className="user-card" style={userCardStyles}>
                            <img src={imgUser} alt="User" className="user-card-img" style={userImageStyles} />
                            <div className="user-card-info">
                                <h4 style={userNameStyles}>{cookieValue.userName}</h4>
                                <p style={userNameStyles}>{cookieValue.email}</p>
                            </div>
                            <button className="logout-button" style={userbutton} onClick={handleLogoutClick}>
                                تسجيل الخروج
                            </button>
                        </motion.div>
                    )}
                </motion.div>
            </motion.div>
            <LogOut
                open={logoutDialogOpen}
                onClose={() => setLogoutDialogOpen(false)}
                onConfirm={functionLogout}
            />
        </>
    );
}

export default Sidebar;
