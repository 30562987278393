import { React, useEffect, useState } from "react";
import Banner from "../../Component/SubmitApplicationComp/Banner";
import Form from "./Form";
import img from "../../Image/Rectangle.png";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Url } from "../../function/FunctionApi";

function SpecialProjects({ userData }) {
  const { id } = useParams();
  const [apiData, setApiData] = useState(null);
  const [error, setError] = useState(null);
  console.log("id issssssssssssssss", id);

  useEffect(() => {
    console.log(
      "lorem sdfbjkhsdfnb dfbsdfbnsdfkb dfbklsndfjsdf bsdkfbnsdfb dsfkbjndfb sdfbjsdf "
    );
    window.scrollTo(0, 0);

    const fetchData = async () => {
      try {
        if (1) {
          const token = userData?.token;

          const response = await axios.get(
            `${Url}/PrivateProject/get-privateProjectByid/?id=${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const filteredData = response.data;
          setApiData(filteredData.data, response.headers);
          console.log("filteredData", filteredData);

          // setApiData(filteredData.length > 0 ? filteredData[0] : null);
        }
      } catch (err) {
        setError("An error occurred while fetching data.");
        console.log("An error occurred while fetching data.");
      }
    };
    fetchData();
    console.log("nnnnmmmmmm");
  }, [id, userData]);

  return (
    <>
      <Banner
        img={img}
        title={"    مشاريع خاصة"}
        Description={"قم بإضافة طلبك وتأكد من إدخال المعلومات بشكل صحيح"}
      />
      <Form userData={userData} apiData={apiData} />
    </>
  );
}

export default SpecialProjects;
