import officeImage from "../Image/engineer-workers-icon 1.png";

export const Riyadh = [
  {
    id: 1,
    name: "خريص",
    value: "Khurais",
    image: officeImage,
  },
  {
    id: 2,
    name: "الشمال",
    value: "North",
    image: officeImage,
  },
  {
    id: 3,
    name: "الشرق",
    value: "East",
    image: officeImage,
  },
  {
    id: 4,
    name: "الجنوب",
    value: "South",
    image: officeImage,
  },
  {
    id: 5,
    name: "الدرعية",
    value: "Diriyah",
    image: officeImage,
  },
];

export const Hail = [
  { id: 1, name: "الحاءل", value: "Hail", image: officeImage },
  { id: 2, name: "بقعاء", value: "Baqaa", image: officeImage },
  { id: 3, name: "الغزاله", value: "Al Ghazalah", image: officeImage },
  { id: 4, name: "الحليفه", value: "Al Hulayfah", image: officeImage },
  { id: 5, name: "موقق", value: "Moqaq", image: officeImage },
  { id: 6, name: "الشملي", value: "Al Shumli", image: officeImage },
  { id: 7, name: "الشنان", value: "Al Shanan", image: officeImage },
  { id: 8, name: "القاعد", value: "Al Qaed", image: officeImage },
];
